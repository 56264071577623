<template>
  <div id="campaigns-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table ref="table" search :data="filteredItems" pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center">
            <router-link v-if="$acl.check('admin')" :to="{name: 'campaignCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ campaigns.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : campaigns.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <template slot="thead">
          <vs-th class="w-4">{{$t('fields.image') | capitalize}}</vs-th>
          <vs-th :sort-key="`title_${$i18n.locale}`" class="">{{$t('fields.title')}}</vs-th>
          <vs-th class="max-w-xs">{{$t('fields.deadlines')}}</vs-th>
          <vs-th class="">{{$t('fields.attached')}}</vs-th>
          <vs-th>{{$t('fields.discount_type')}}</vs-th>
          <vs-th>{{$t('fields.discount_amount')}}</vs-th>
          <vs-th>{{$t('fields.type')}}</vs-th>
          <vs-th>{{$t('fields.actions')}}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3" :state="data[indextr].isActive ? '' : 'danger'">
            <vs-td>
              <div class="img" :class="{'sale': data[indextr].discount}">
                <img v-if="tr.image_isAdded" :src="`${$baseUrl}/campaigns/preview/${tr.uuid}.webp`" alt="">
              </div>
            </vs-td>
            <vs-td :data="data[indextr][`title_${$i18n.locale}`]">
              {{ data[indextr][`title_${$i18n.locale}`] }} 
            </vs-td>
            <vs-td :data="data[indextr].discount">
              <template v-if="data[indextr].discount">
                {{ new Date(data[indextr].discount.from).toLocaleString('ru-RU') }}
                <br> 
                {{ new Date(data[indextr].discount.to).toLocaleString('ru-RU') }}
              </template>
              <span v-else> No discount! </span>
            </vs-td>
            <vs-td>
              <template v-if="data[indextr].categoryId || data[indextr].brandId">
                {{ data[indextr].categoryId ? 'Category' : 'Brand' }} > 
                {{ data[indextr].categoryId ? data[indextr].categoryId : data[indextr].brandId }} 
              </template>
              <template v-else>None</template>
            </vs-td>
            <vs-td :data="data[indextr].type">
              <template v-if="data[indextr].discount">
                <vs-chip :color="data[indextr].discount.type === 'bonused' ? 'success' : data[indextr].discount.type === 'percentage' ? 'primary' : 'warning'">
                  <template v-if="data[indextr].discount.type">{{data[indextr].discount.type | capitalize}}</template>
                </vs-chip>
              </template>
            </vs-td>
            <vs-td :data="data[indextr].discount">
              <template v-if="data[indextr].discount && tr.discount.type === 'bonused'">
                <span class="font-semibold">{{ data[indextr].discount.required }}+{{ data[indextr].discount.bonus }}</span>
              </template>
              <span v-else-if="data[indextr].discount && tr.discount.type === 'percentage'" class="font-semibold">{{ data[indextr].discount.amount }}%</span>
              <span v-else-if="data[indextr].discount" class="font-semibold">{{ data[indextr].discount.amount }}TMT</span>
            </vs-td>
            <vs-td :data="data[indextr].type">
              <template v-if="data[indextr].discount">
                <vs-chip :color="data[indextr].discount.dep_type === 'both' ? 'success' : data[indextr].discount.dep_type === 'express' ? 'primary' : 'warning'">
                  <template v-if="data[indextr].discount.dep_type">{{data[indextr].discount.dep_type | capitalize}}</template>
                </vs-chip>
              </template>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" @click.stop="showData(tr.uuid)" />
              <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr.uuid)" />
              <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isActive)" />
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      campaigns: [],
      itemsPerPage: 10,
      isLoading: true
    }
  },
  computed: {
    filteredItems () { return this.campaigns },
    currentPage () {
      if (!this.isLoading) { return this.$refs.table.currentx }
      return 0
    },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.campaigns.length }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('/campaigns').then(response => {
        this.isLoading = false
        this.campaigns = response.data.data
      }).catch(err => {
        this.isLoading = false
        this.notify('Error', err.message)
      })
    },
    editData (id) { this.$router.push(`/campaigns/${id}/edit`) },
    showData (id) { this.$router.push(`/campaigns/${id}`) },
    validate (id) {
      const camp = this.campaigns.find(el => el.uuid === id)
      if (!camp.isActive) return camp.image_isAdded && camp.title_tm && camp.title_ru && camp.title_en && camp.description_tm && camp.description_ru && camp.description_en
      else return true
    },
    async changeStatus (id, status) {
      if (this.validate(id)) {
        await this.$http.patch(`/campaigns/edit/${id}`, {
          isActive: !status
        }).then(() => {
          this.notify('Success', 'Product status successfully updated!')
          this.fetchData()
        }).catch(err => {
          this.notify('Error', err.message)
        })
      } else {
        this.notify('Error', 'Campaign is not valid for publish!')
      }
    },
    notify (title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: title === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: title === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
#campaigns-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
  .stat_btn {
    color: #626262;
    .vx-card {
      border: 1px solid transparent;
    }
    &:hover {
      cursor: pointer;
      color: #7367F0;
      .vx-card {
        background-color: #ffffff;
        box-shadow: 0px 2px 5px 0px rgba(200, 200, 200, 10%);
      }
    }
    &:active {
      .vx-card {
        color: white;
        background-color: #7367F0;
        box-shadow: 0px 1px 2px 0px rgba(200, 200, 200, 10%);
      }
    }
    &.current {
      color: #7367F0;
      .vx-card {
        border: 1px solid #7367F0;
      }
    }
  }
  .img {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    &.sale {
      outline: rgba(234,84,85,0.8) solid 3px;
      box-shadow: 0px 0px 15px rgba(234,84,85,1);
    }
  }
}
</style>